import Cookies from 'js-cookie';
export function ageConfFunc() {
	//console.log('呼んだ？');
//
//  /*ローディング画面を取り消し*/
//  $('#LoadingTop').fadeOut(800); // ローディングを隠す
//
//  var ageConfirmationModal, ageY, ageN, ageScrollpos, ageConf, decision;
//  ageConfirmationModal = $('#ageConfirmationModal');
//  decision = ageConfirmationModal.length;
//  ageY = $('#ageY');
//  ageN = $('#ageN');
//  ageConf = Cookies.get('age');
//  if (decision) {
//    if (ageConf) {
//      setTimeout(function () {
//        idxTopSliderMovieFunc();
//        return;
//      }, 800);
//    } else {
//      ageConfirmationModal.fadeIn();
//      ageScrollpos = $(window).scrollTop();
//      $('body').addClass('fixedBody').css({
//        'top': -ageScrollpos
//      });
//    }
//  }
//  ageY.on('click', function () {
//    Cookies.set('age', 'true', {
//      expires: 1 / 2
//    });
//    ageConfirmationModal.fadeOut();
//    $('body').removeClass('fixedBody').css({
//      'top': 0
//    });
//    window.scrollTo(0, ageScrollpos);
//    idxTopSliderMovieFunc();
//  });
//  ageN.on('click', function () {
//    Cookies.remove('age');
//  });
}