import Cookies from 'js-cookie';
export function languageFunc() {
	let langJa, langEn;
	let getUrlParam, setSelectLang;
	let jsLangM;
	jsLangM = $('#jsLangM');
	langJa = $('p.ja');
	langEn = $('p.en');

	
	//変数にCookieを代入
	setSelectLang = Cookies.get('Lang');
	console.log(setSelectLang);
	
	//言語が未選択の場合、modalを表示
	if(!setSelectLang){
		jsLangM.fadeIn();
	}
	
	if (setSelectLang == 'EN') {
		langEn.each(function (i, elem) {
			$(this).css('display', 'block');
		});
	} else if (setSelectLang == 'JP') {
		langJa.each(function (i, elem) {
			$(this).css('display', 'block');
		});
	}
//http://wp-practice.test/wp/wp-admin/admin-ajax.php
$.ajax({
  url: "http://wp-practice.test/wp/wp-admin/admin-ajax.php", // URLを指定
  type: "POST", // GET,POSTなどを指定
  data: { // データを指定
		'action' : 'view_sitename',
    'param1' : "AAA",
    'param2' : "BBB"
  }
}).done(function (data) {
  // 通信成功時のコールバック処理
	console.log(data);
}).fail(function (data) {
  // 通信失敗時のコールバック処理
}).always(function (data) {
  // 常に実行する処理
});
	
//	$.getJSON('http://wp-practice.test/wp-json/mytheme/v1/myroute')
//		.done(function (json) {
//				console.log(json);
//		})
//		.fail(function () {
//				console.log('失敗');
//		});
	
	//	let getUrlParam,setSelectLang;
	//	let getReferrer;
	//	let jsLangM;
	//	jsLangM = $('#jsLangM');
	//	//Cookieで現在の言語を取得
	//	setSelectLang = Cookies.get('Lang');
	//	console.log(setSelectLang);
	//	
	//	//言語選択判定
	//	if(setSelectLang == 'undefined'){
	//		console.log('言語は未選択');
	//	}else{
	//		console.log('言語は'+ setSelectLang);
	//		
	//	}

	//	//Cookieに言語が入っているかチェック
	//	if($.isEmptyObject(setSelectLang)){
	//		console.log('言語は未選択');
	//	}else{
	//		console.log(setSelectLang);
	//		jsLangM.fadeOut();
	//		
	//		//$_SESSION["username"] = "yamada";
	//	}
}