import Cookies from 'js-cookie';
export function buttonActionFunc() {
	let getLang, langSelect;
	let jsLangM;
	jsLangM = $('#jsLangM');
	getLang = $('.lang');
	
	getLang.on('click', function () {
		langSelect = $(this).val();
		if (langSelect == 'EN') {
			Cookies.set('Lang', 'EN', {
				expires: 1
			});
			location.reload();
//			let setParam = '?lang=en';
//			history.pushState(null, null, setParam);
//			location.search = setParam;
			
		} else {
			Cookies.set('Lang', 'JP');
			location.reload();
		}
	});

}