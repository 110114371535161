import {
  languageFunc
} from "./common/_lang";
import {
  buttonActionFunc
} from "./common/_buttonAction";

import {
  ageConfFunc
} from "./common/_age";

//* -------------------------------------------------------------------
//通常実行
//------------------------------------------------------------------- */
languageFunc();
let indexTopLen, setTimer, setResponsibleCheck;
//* ----------------------------------------------------------------------------------
//window実行
//---------------------------------------------------------------------------------- */
////// ロード時
let indexTop = $('#indexTop').length;
$(window).on('load', function () {
  buttonActionFunc();
	ageConfFunc();
  
  
  
  $('.login-username').find('label').text('メールアドレス')
  
  console.log();
  
  
});

// リサイズ時
/*リサイズ後,200msで発火する設定*/
$(window).on('resize', function () {

});

// スクロール時
$(window).on('scroll', function () {
	
});
///* ----------------------------------------------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
    navigator.userAgent.indexOf('iPhone') > 0) ||
  (navigator.userAgent.indexOf('iPod') > 0) ||
  (navigator.userAgent.indexOf('iPad') > 0) ||
  (navigator.userAgent.indexOf('Android') > 0) ||
  (navigator.userAgent.indexOf('BlackBerry') > 0) ||
  (navigator.userAgent.indexOf('PlayBook') > 0) ||
  (navigator.userAgent.indexOf('Kindle') > 0) ||
  (navigator.userAgent.indexOf('Silk') > 0) ||
  (navigator.userAgent.indexOf('Windows Phone') > 0) ||
  (navigator.userAgent.indexOf('Nokia') > 0)
) {
  // スマホ・タブレット時の処理
  uaFlag = 1;
} else {
  // PC時の処理
  uaFlag = 3;
}
/* ----------------------------------------------------------------------------------
IEだけの時
---------------------------------------------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}